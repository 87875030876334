import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaPaste } from "react-icons/fa";
import { setIsLoggedIn, setIsLoading } from "../../../actions";
import BacktoLogin from "../components/BackToLogin";
import {
    verifyOTP2FA,
} from "../../../api/apiCall";

function OTPVerify() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [otpOne, setOtpOne] = useState("");
    const [otpTwo, setOtpTwo] = useState("");
    const [otpThree, setOtpThree] = useState("");
    const [otpFour, setOtpFour] = useState("");
    const [otpFive, setOtpFive] = useState("");
    const [otpSix, setOtpSix] = useState("");
    // const [buttonDisabled, setButtonDisabled] = useState(true);
    const [error, setError] = useState(null);

    const handleRecoveryCodes = () => {
        navigate("/enter-recovery-codes");
    };

    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    useEffect(() => {
        const allFilled = [otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix].every(value => value.length === 1);
        // setButtonDisabled(!allFilled);

        if (allFilled) {
            handleVerify();
        }

    }, [otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix]);

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (value.length > 1) {
            e.target.value = value.slice(0, 1);
        }
        const otpSetters = [setOtpOne, setOtpTwo, setOtpThree, setOtpFour, setOtpFive, setOtpSix];
        otpSetters[index](value);

        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            if (text.length === 6 && /^[0-9]+$/.test(text)) {
                const otpSetters = [setOtpOne, setOtpTwo, setOtpThree, setOtpFour, setOtpFive, setOtpSix];
                text.split("").forEach((char, index) => {
                    otpSetters[index](char);
                    inputRefs[index].current.value = char;
                });
                inputRefs[5].current.focus();
            } else {
                console.error("Invalid OTP format. Make sure it's 6 digits.");
            }
        } catch (error) {
            console.error("Failed to read clipboard contents: ", error);
        }
    };

    const handleVerify = async () => {
        dispatch(setIsLoading(true));
        try {
            let data = {
                id: user.id,
                token: token,
                payloads: {
                    email: user.email,
                    otp: `${otpOne}${otpTwo}${otpThree}${otpFour}${otpFive}${otpSix}`
                }
            }

            const response = await verifyOTP2FA(data);
            if (response.status === 200) {
                localStorage.setItem('isLoggedIn', true);
                dispatch(setIsLoggedIn(true));
                navigate("/dashboard/messages");
            }
            else {
                // Display error message
                console.error("Failed to verify OTP: ", response.message);
                setError('Invalid OTP!');
            }
        }
        catch (error) {
            console.error("Error verifying your OTP: ", error);
            setError('Invalid OTP!');
        }
        finally {
            dispatch(setIsLoading(false));
        }
    }

    return (
        <div className="flex flex-col items-center px-20 py-20 text-base bg-[#F9FAFB] min-h-screen flex-grow max-md:px-5">
            <div className="flex flex-col p-6 mt-6 max-w-full bg-white border border-gray-200 rounded-2xl shadow-sm w-[400px] max-md:px-5">
                <div className="flex items-center justify-between w-full mb-4">
                    <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
                        <BacktoLogin />
                    </div>
                    <img
                        src="/FullIDCheckLogo.svg"
                        alt="Devreg Logo"
                        className="h-8"
                    />
                </div>
                <hr className="w-full border-t border-gray-200" />
                <div className="mt-8 text-xl font-semibold text-neutral-800 max-sm:mr-auto text-start">
                    Two Step Verification
                </div>
                <div className="mt-2 mb-2 text-base text-zinc-700 text-opacity-80 max-sm:text-sm max-md:text-md">
                    Open your two factor authenticator app.
                </div>
                {error && (
                    <>
                        <small className="text-red-600 text-center">{error}</small>
                    </>
                )}

                <div className="flex gap-4 mt-2 items-center justify-center">
                    {[otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix].map((otp, index) => (
                        <input
                            key={index}
                            type="text"
                            className={`flex-1 shrink-0 px-3 rounded-md border border-solid h-[46px] w-[30px] max-[320px]:px-0 text-center ${error ? 'border-red-600' : 'border-zinc-700 border-opacity-20'}`}
                            maxLength={1}
                            value={otp}
                            onChange={(e) => handleInputChange(e, index)}
                            ref={inputRefs[index]}
                        />
                    ))}
                </div>
                <div
                    onClick={handlePaste}
                    className="flex flex-row gap-1 cursor-pointer justify-center items-center px-9 py-3 mt-7 text-base font-semibold text-white whitespace-nowrap rounded-3xl max-md:px-5 text-center bg-teal-300"
                >
                    <FaPaste size={20} />
                    Paste from Clipboard
                </div>
                <button
                    onClick={() => handleVerify()}
                    className={`cursor-pointer justify-center items-center px-9 py-3 mt-4 text-base font-semibold text-white whitespace-nowrap rounded-3xl max-md:px-5 text-center bg-blue-900`}
                >
                    Verify
                </button>
                <div onClick={handleRecoveryCodes} className="cursor-pointer self-center mt-4 text-base text-center ">
                    <span className="font-normal">
                        Use <span className="text-blue-400 underline underline-offset-4">Recovery Code</span> instead
                    </span>
                </div>
            </div>
        </div>
    );
}

export default OTPVerify;