import React from "react";
import SidePanel from "../../component/SidePanel";
import TopBanner from "../../component/TopBanner";

function WhyRegister() {
  const imgUrl =
    "/whyRegisterSidePanel.png";

  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      <TopBanner bannerImg={"/Why-Register-Banner.png"} />
      <div className="flex mt-16 w-full m-auto max-md:flex-col max-w-[1366px] pb-16 max-md:mt-5 px-5">
        <div className="section-1 w-[75%] max-md:w-full">
          <div className="flex flex-col grow self-stretch max-md:mt-10 max-md:max-w-full">
            <div className="text-3xl font-bold tracking-tight leading-9 text-blue-900 max-md:max-w-full">
              At Full ID Check, we welcome everyone—professionals, freelancers, or individuals from all walks of life.
            </div>
            <div className="mt-2 text-base tracking-normal leading-7 text-neutral-600 max-md:max-w-full">
              Our platform allows you to prove your identity, authenticity, and credibility. By registering, you’ll provide the required legal documents, take part in a live interview, and confirm your identity with our secure facial recognition system.
              <br />
              Full ID Check empowers everyone to confidently say, “I am exactly who I say I am.”
              Whether you're building trust with clients, seeking new opportunities, or simply showcasing your legitimacy, Full ID Check is here to help.
            </div>
            <div className="mt-12 text-3xl font-bold tracking-tight leading-9 text-neutral-600 max-md:mt-10 max-md:max-w-full">
              Why Register at FullIDCheck?
            </div>
            <div className="justify-center mt-9 max-md:max-w-full">
              {/* FIX----------------- */}
              <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-lg:gap-2  max-xl:gap-0">
                <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch px-4 py-6 w-full text-2xl max-lg:text-lg max-lg:font-bold font-semibold tracking-tight leading-6 text-center bg-white rounded-2xl border border-solid border-zinc-300 text-neutral-800 max-md:mt-4">
                    <img
                      alt="img"
                      loading="lazy"
                      src="/Reputation.svg"
                      className="self-center aspect-[1.72] w-[97px]"
                    />
                    <div className="mt-2">Reputation Boost</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 max-lg:ml-0 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch px-4 py-6 w-full text-2xl max-lg:text-lg max-lg:font-bold font-semibold tracking-tight leading-6 text-center bg-white rounded-2xl border border-solid border-zinc-300 text-neutral-800 max-md:mt-4">
                    <img
                      alt="img"
                      loading="lazy"
                      src="/whyRegister-Increased.svg"
                      className="self-center aspect-[0.91] w-[51px]"
                    />
                    <div className="mt-2">Increased Trust</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 max-lg:ml-0 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch px-4 py-6 w-full text-2xl max-lg:text-lg max-lg:font-bold  font-semibold tracking-tight leading-6 text-center bg-white rounded-2xl border border-solid border-zinc-300 text-neutral-800 max-md:mt-4">
                    <img
                      alt="img"
                      loading="lazy"
                      src="/whyRegister-Unlimited.svg"
                      className="self-center aspect-[1.61] w-[91px]"
                    />
                    <div className="mt-2">Unlimited Opportunities</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 max-lg:ml-0 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch px-4 py-6 w-full text-2xl max-lg:text-lg max-lg:font-bold  font-semibold tracking-tight leading-6 text-center bg-white rounded-2xl border border-solid border-zinc-300 text-neutral-800 max-md:mt-4">
                    <img
                      alt="img"
                      loading="lazy"
                      src="/whyRegister-Community.svg"
                      className="self-center aspect-[1.49] w-[95px]"
                    />
                    <div className="mt-4">Community Building</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 text-3xl font-bold tracking-tight leading-9 text-blue-900 max-md:mt-10 max-md:max-w-full">
              What We Offer
            </div>
            <div className="flex flex-col gap-3 mt-6 text-neutral-600 max-md:max-w-full">
              <div className="flex flex-col justify-center px-5 py-6 bg-white rounded-2xl max-md:max-w-full border border-[#E5E7EB] max-md:p-5">
                <div className="flex gap-5 px-5 max-md:px-0 max-md:pr-0 max-md:flex-wrap max-md:flex-col justify-start">
                  <img
                    alt="img"
                    loading="lazy"
                    src="/whyRegister-Registered.svg"
                    className="shrink-0 self-start max-w-full aspect-[1.56] w-[100px]"
                  />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-6 max-md:max-w-full">
                      Being Registered
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-6 max-md:max-w-full">
                      Imagine this: You’re registered on Full ID Check and have advertised goods for sale. A potential buyer contacts you and agrees to purchase but hesitates to pay upfront because they don’t know you.
                      <br />
                      Since you’re a verified member, the buyer can easily confirm your identity by clicking the verification button on your profile. This gives them the confidence they need to proceed, knowing you’re genuine and not a scammer.
                      <br />
                      Being registered means more trust, smoother transactions, and peace of mind for everyone involved.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center px-5 py-6 mt-4 bg-white rounded-2xl max-md:max-w-full border border-[#E5E7EB] max-md:p-5">
                <div className="flex gap-5 px-5 max-md:px-0 max-md:pr-0 max-md:flex-wrap max-md:flex-col">
                  <img
                    alt="img"
                    loading="lazy"
                    src="/whyRegister-Personalized.svg"
                    className="shrink-0 self-start aspect-[0.91] w-[58px]"
                  />
                  <div className="flex flex-col flex-1 min-[768px]:ml-8 max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-6 max-md:max-w-full">
                      Personalized Profile
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-6 max-md:max-w-full">
                      Whether you’re a professional or an individual, your profile on Full ID Check is a powerful tool. For professionals, it serves as a detailed portfolio showcasing your skills and experience. For individuals, it confirms your identity, providing peace of mind to anyone you interact with. By completing your profile with accurate information, you make it easier for others to trust you and engage with you.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify- px-5 py-6 mt-4 bg-white rounded-2xl max-md:max-w-full border border-[#E5E7EB]  max-md:p-5">
                <div className="flex gap-5 px-5 max-md:px-0 max-md:pr-0 max-md:flex-wrap max-md:flex-col">
                  <img
                    alt="img"
                    loading="lazy"
                    src="/whyRegister-OurForums.svg"
                    className="shrink-0 self-start w-16 aspect-square"
                  />
                  <div className="flex flex-col  min-[768px]:ml-6 flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-6 max-md:max-w-full">
                      Our Forums
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-7 max-md:max-w-full">
                      Our forums are a trusted space for all registered users—professionals, freelancers, or individuals. Here, you can confidently connect with others knowing everyone has been thoroughly verified. The forums are a place to find local experts, share knowledge, and collaborate on projects. Whether you're looking to hire, offer your skills, or simply engage with a community, our forums provide a secure, scam-free environment for networking and growth.
                      <br />
                      <br />
                      What you can do in the forums: <br />
                      <ul className="list-disc ml-5">
                        <li>Network with Verified Users: Connect with other registered individuals, professionals, or freelancers for potential opportunities, knowing each person's identity is verified.</li>
                        <br /><li>Seek Local Experts: Find people in your area or field to expand your network or offer services.</li>
                        <br /><li>Exchange Knowledge: Share expertise or ask for assistance in a supportive community committed to professional growth and collaboration.</li>
                      </ul>
                      <br />
                      We are committed to maintaining a respectful, constructive space for all members, where people can elevate each other personally and professionally.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SidePanel image={imgUrl} />
      </div>
    </div>
  );
}

export default WhyRegister;
