import React, { useState, useEffect } from "react";
import BacktoLogin from "../components/BackToLogin";
import { useNavigate } from "react-router-dom";
import {
  getRecoveryCodes,
  generatetRecoveryCodes,
} from "../../../api/apiCall";
import { LiaDownloadSolid, LiaPrintSolid, LiaCopySolid } from "react-icons/lia";

function RecoveryCodes() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  const [isCopied, setIsCopied] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetRecoveryCodes();
  }, [])

  const handleGetRecoveryCodes = async () => {
    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getRecoveryCodes(data);
      if (response.status === 200) {
        console.log(response);
        setRecoveryCodes(response.data.recovery_codes);
      }
    }
    catch (error) {
      console.log("Error getting recovery codes: ", error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleRegenerateRecoveryCodes = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {

        }
      }

      const response = await generatetRecoveryCodes(data);
      if (response.status === 200) {
        console.log(response);
        setRecoveryCodes(response.data.recovery_codes);
      }
    }
    catch (error) {
      console.log("Error getting recovery codes: ", error);
    }
  }

  const handleDownload = () => {
    const textContent = recoveryCodes.join("\n");
    const blob = new Blob([textContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Devreg Recovery Codes.txt";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handlePrint = () => {
    const printContent = recoveryCodes.join("\n");
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`<pre>${printContent}</pre>`);
    newWindow.print();
  };

  const handleCopy = () => {
    const textContent = recoveryCodes.join("\n");

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textContent).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      }).catch(err => {
        console.error("Failed to copy text: ", err);
      });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  return (
    <div className="flex flex-col items-center px-20 py-20 text-base bg-[#F9FAFB] min-h-screen flex-grow max-md:px-5">
      <div className="flex flex-col p-6 max-w-full bg-white border border-gray-200 rounded-2xl shadow-sm w-[650px] max-md:px-5">
        <div className="flex items-center justify-between w-full mb-6">
          <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
            <BacktoLogin />
          </div>
          <img
            src="/FullIDCheckLogo.svg"
            alt="Devreg Logo"
            className="h-8"
          />
        </div>
        <hr className="w-full border-t border-gray-200" />
        <div className="flex flex-col mt-6 w-full">
          <div className="self-start text-lg font-semibold text-neutral-800 md:text-xl">
            Two-factor recovery codes
          </div>
          <div className="mt-2 text-sm text-zinc-700 text-opacity-80 md:text-base">
            Recovery codes can be used to access your account in the event you
            lose access to your device and cannot receive two-factor
            authentication codes.
          </div>
        </div>
        <div className="flex-1 shrink gap-2.5 p-4 mt-6 w-full text-sm font-[400] text-[#6B7280] tracking-normal leading-6 bg-gray-200 rounded-xl md:text-base">
          Keep your recovery codes in a safe spot. These codes are the last
          resort for accessing your account in case you lose your password and
          second factors. If you cannot find these codes, you will lose access
          to your account.
        </div>
        <div className="p-4 w-full rounded-xl border border-gray-400 md:p-6 mt-6">
          <div className="flex flex-col w-full text-sm font-medium tracking-normal leading-6 text-black md:flex-row md:justify-start">
            {isLoading ? (
              <div className="w-full inset-0 flex flex-col items-center justify-center z-50">
                <div className="relative flex justify-center items-center">
                  <div className="h-10 w-10 rounded-full border-t-2 border-b-2 border-gray-200"></div>
                  <div className="absolute top-0 left-0 h-10 w-10 rounded-full border-t-2 border-b-2 border-devRegBlue animate-spin"></div>
                </div>
              </div>
            ) : (
              <>
                <div className="w-full md:w-[200px] space-y-2 md:space-y-5 px-2 mt-2">
                  {recoveryCodes.slice(0, 5).map((code, index) => (
                    <div key={index}>• {code}</div>
                  ))}
                </div>
                <div className="w-full md:w-[200px] space-y-2 md:space-y-5 px-2 mt-2">
                  {recoveryCodes.slice(5).map((code, index) => (
                    <div key={index}>• {code}</div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col max-sm:flex-row max-sm:gap-1 max-sm:items-center mt-8 space-y-4 max-sm:space-y-0 max-sm:space-x-4 text-sm md:flex-row md:space-y-0 md:space-x-4 md:items-center max-md:justify-center max-[360px]:justify-center">
            <button
              onClick={handleDownload}
              className="flex gap-3 items-center px-4 py-2 border border-[#727272] rounded-full hover:bg-gray-100 max-sm:gap-1 max-sm:justify-center"
            >
              <LiaDownloadSolid size={18} />
              <div className="max-[360px]:hidden">Download</div>
            </button>
            <button
              onClick={handlePrint}
              className="flex gap-3 items-center px-4 py-2 border border-[#727272] rounded-full hover:bg-gray-100  max-sm:gap-1 max-sm:justify-center"
            >
              <LiaPrintSolid size={18} />
              <div className="max-[360px]:hidden">Print</div>
            </button>
            <button
              onClick={handleCopy}
              className="flex gap-3 items-center px-4 py-2 border border-[#727272] rounded-full hover:bg-gray-100  max-sm:gap-1 max-sm:justify-center"
            >
              <LiaCopySolid size={18} />
              <div className="max-[360px]:hidden">{isCopied ? "Copied!" : "Copy"}</div>
            </button>
          </div>
        </div>
        <div className="flex gap-6 mt-8 w-full max-md:flex-col max-md:gap-4 max-md:mt-4">
          <button
            className="px-4 py-2.5 w-1/2 max-md:w-full text-base text-white font-medium bg-blue-900 rounded-full"
            onClick={handleRegenerateRecoveryCodes}
          >
            Generate New Recovery Codes
          </button>
          <button
            className="px-4 py-2.5 w-1/2 max-md:w-full text-base font-medium text-blue-900 bg-blue-100 rounded-full"
            onClick={() => navigate("/login")}
          >
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default RecoveryCodes;
