import React, { useState, useRef, useEffect } from "react";
import {
  getLanguages,
  addLanguage,
  deleteLanguage,
  updateLanguage,
} from "../../../../api/apiCall.js";
import { HiMiniXMark } from "react-icons/hi2";
import { GoPlus } from "react-icons/go";
import { FaCheck } from "react-icons/fa6";

function Language() {
  const user = JSON.parse(localStorage.getItem('user'));
  const storedLanguages = JSON.parse(localStorage.getItem('languages'));
  const token = localStorage.getItem('token');
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [languages, setLanguages] = useState(storedLanguages)
  const [languagesArray, setLanguagesArray] = useState(storedLanguages)
  const inputRefs = useRef([]);
  const [loadId, setLoadId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [visible, setVisible] = useState(true);
  const [isEditing, setIsEditing] = useState({ status:false, index:null });

  useEffect(() => {
    if (updateSuccess) {
      const timer = setTimeout(() => {
        setVisible(false);
        setUpdateSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [updateSuccess]);

  const handleClose = () => {
    setVisible(false);
    setUpdateSuccess(false);
  };

  const handleGetLanguages = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getLanguages(data);
      console.log("Languages: ", response)
      if (response.status === 200) {
        setLanguages(response.data);
        setLanguagesArray(response.data);
        localStorage.setItem("languages", JSON.stringify(response.data));
      }
    }
    catch (error) {
      console.log("Error getting languages: ", error);
    }
    finally {
      setLoadId(null);
      setIsAdding(false);
      setIsUpdating(false);
      setIsEditing({ status:false, index:null });
    }
  };

  const handleAddLanguage = async (index) => {
    setIsAdding(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          language: languagesArray[index]
        }
      }

      const response = await addLanguage(data);
      if (response.status === 201) {
        // setLanguages((prevLanguages) => [...prevLanguages, languagesArray[index]]);
        handleGetLanguages();
      }
    }
    catch (error) {
      console.log("Error adding language: ", error);
    }
    finally {
      setLoadId(null);
      setIsAdding(false);
      setIsUpdating(false);
      setIsEditing({ status:false, index:null });
    }
  }

  const handleSaveEdit = async (index) => {
    setIsUpdating(true);
    try {
      let data = {
        id: user.id,
        languageId: languages[index].id,
        token: token,
        payloads: {
          language: languagesArray[index]
        }
      }

      const response = await updateLanguage(data);
      if (response.status === 200) {
        // setLanguages((prevLanguages) => [...prevLanguages, languagesArray[index]]);
        await handleGetLanguages();
      }
    }
    catch (error) {
      console.log("Error adding language: ", error);
    }
    finally {
      setLoadId(null);
      setIsAdding(false);
      setIsUpdating(false);
      setIsEditing({ status:false, index:null });
    }
  }

  const handleDeleteLanguage = async (languageId) => {
    setLoadId(languageId);
    try {
      let data = {
        id: user.id,
        token: token,
        languageId: languageId
      }

      const response = await deleteLanguage(data);
      if (response.status === 200) {
        handleGetLanguages();
      }
    }
    catch (error) {
      console.log("Error deleteing language: ", error);
    }
  }

  const handleAddLanguageArray = () => {
    setLanguagesArray((prevLanguagesArray) => [...prevLanguagesArray, '']);
  };

  const handleChange = (e, index) => {
    const newKeywords = [...languagesArray];
    newKeywords[index] = e.target.value;
    setLanguagesArray(newKeywords);
  };

  useEffect(() => {
    handleGetLanguages();
  }, [])

  useEffect(() => {
    if(isEditing.status === false && isEditing.index === null){
      if (languagesArray.length > 0) {
        inputRefs.current[languagesArray.length - 1].focus();
      }
    }
    if(isEditing.status === true && isEditing.index !== null){
      inputRefs.current[isEditing.index].focus();
    }
    
  }, [languagesArray, isEditing]);


  return (
    <>
      <div className="flex flex-col pt-6 max-md:px-3 max-md:pt-0 pb-20 max-md:mt-8 max-md:max-w-full">
        <div className="flex flex-col text-base tracking-tight max-md:flex-wrap">
          <div className="leading-[110%] font-bold text-base mb-2">Add Language</div>
          <p className="text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">Let us know what languages you speak! Adding them to your profile helps you connect with others and makes your experience more personal.</p>
        </div>
        <div className="flex gap-2 p-2.5 mt-6 text-sm leading-4 whitespace-nowrap rounded-lg border border-solid border-neutral-300 text-neutral-600 flex-wrap max-md:pr-5">
          {languagesArray.map((language, index) => (
            <form key={index} className="relative" onSubmit={(e) => {
                e.preventDefault(); 
                if(isEditing.status){
                  handleSaveEdit(index)
                }
                else{
                  handleAddLanguage(index)
                }
            }}>
              {languages.includes(language) ? (
                <input
                  type="text"
                  className={`px-4 py-2 rounded-lg ${
                    isEditing.status && isEditing.index === index
                      ? "bg-neutral-100"
                      : "bg-devRegBlue text-white"
                  }`}
                  value={language.language}
                  readOnly={!isEditing.status || isEditing.index !== index}
                  disabled={isEditing.status && isEditing.index !== index}
                  onChange={(e) => {
                    setLanguagesArray(
                      languagesArray.map((item, i) =>
                        i === isEditing.index ? { ...item, language: e.target.value } : item
                      )
                    );
                  }}
                  size={(language.language || "").length || 1}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onDoubleClick={() => setIsEditing({ status: true, index: index })}
                />
              ) : (
                <input
                  type="text"
                  className="justify-center px-4 py-2 rounded-lg bg-neutral-100"
                  value={language.language}
                  onChange={(e) => handleChange(e, index)}
                  size={language.length || 1}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              )}

              {isEditing.status && isEditing.index === index ? (
                <button
                  type="submit"
                  className="absolute top-[5.5px] right-1 px-[2px] py-[2px] text-white bg-green-500 rounded-full"
                >
                  {isUpdating ? (
                    <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                  ) : (
                    <FaCheck size={15}/>
                  )}
                </button>
              ):
                languages.includes(language) ? (
                  <button
                    type="button"
                    className="absolute top-[5.5px] right-1 px-[2px] py-[2px] text-white bg-red-500 rounded-full"
                    onClick={() => handleDeleteLanguage(language.id)}
                  >
                    {loadId === language.id ? (
                      <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                    ) : (
                      <HiMiniXMark size={15}/>
                    )}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="absolute top-[5.5px] right-1 px-[2px] py-[2px] text-white bg-green-500 rounded-full"
                  >
                    {isAdding ? (
                      <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                    ) : (
                      <GoPlus size={15}/>
                    )}
                  </button>
                )
              }
            </form>
          ))}
          <button
            className={`justify-center px-2.5 py-2 rounded-full border border-neutral-200 bg-neutral-100 bg-gray-400 text-black ${(isAdding || loadId !== null) ? "opacity-50 cursor-not-allowed" : ""
              }`}
            onClick={handleAddLanguageArray}
            disabled={isAdding || loadId !== null || isEditing.status}
          >
            <GoPlus size={20}/>
          </button>
        </div>
      </div>
    </>
  );
}

export default Language;
