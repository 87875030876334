import React, { useState, useEffect } from "react";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { FiPlus, FiMinus } from "react-icons/fi";
import { BiReset } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";

const AboutProfile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragState, setDragState] = useState({
    isDragging: false,
    startX: 0,
    startY: 0,
    translateX: 0,
    translateY: 0,
    prevTranslateX: 0,
    prevTranslateY: 0,
  });

  // Pinch state
  const [initialPinchDistance, setInitialPinchDistance] = useState(0);
  const [pinchZoomStartLevel, setPinchZoomStartLevel] = useState(1);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape" && selectedImage) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage]);

  useEffect(() => {
    if (selectedImage) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [selectedImage]);

  const openImage = (src) => {
    setSelectedImage(src);
    setZoomLevel(1);
    setDragState({
      isDragging: false,
      startX: 0,
      startY: 0,
      translateX: 0,
      translateY: 0,
      prevTranslateX: 0,
      prevTranslateY: 0,
    });
  };

  const closeModal = () => {
    setSelectedImage(null);
    setZoomLevel(1);
  };

  const zoomIn = () => {
    setZoomLevel((prevZoomLevel) => {
      const previousZoom = prevZoomLevel;
      const newZoomLevel = previousZoom * 1.5; // Incrementally increase zoom
      const scaleFactor = newZoomLevel / previousZoom;

      setDragState((prevState) => ({
        ...prevState,
        translateX: prevState.translateX * scaleFactor,
        translateY: prevState.translateY * scaleFactor,
      }));

      return newZoomLevel;
    });
  };

  const zoomOut = () => {
    setZoomLevel((prevZoomLevel) => {
      const previousZoom = prevZoomLevel;
      const newZoomLevel = Math.max(previousZoom * 0.8, 0.1); // Allow further zoom out
      const scaleFactor = newZoomLevel / previousZoom;

      setDragState((prevState) => ({
        ...prevState,
        translateX: prevState.translateX * scaleFactor,
        translateY: prevState.translateY * scaleFactor,
      }));

      return newZoomLevel;
    });
  };

  const resetView = () => {
    setZoomLevel(1);
    setDragState({
      isDragging: false,
      startX: 0,
      startY: 0,
      translateX: 0,
      translateY: 0,
      prevTranslateX: 0,
      prevTranslateY: 0,
    });
  };

  const handleWheel = (e) => {
    e.preventDefault();

    // Get the mouse position relative to the image
    const rect = e.target.getBoundingClientRect();
    const mouseX = e.clientX - rect.left; // Mouse position X relative to image
    const mouseY = e.clientY - rect.top;  // Mouse position Y relative to image

    const delta = e.deltaY > 0 ? -0.5 : 0.5; // Adjust zoom speed (faster zooming)
    const previousZoom = zoomLevel;

    // Update zoom level while keeping it within allowed limits
    const newZoomLevel = Math.max(zoomLevel + delta, 0.1); // Prevent zooming out beyond 0.1x
    setZoomLevel(newZoomLevel);

    // Update translation when zooming in
    if (newZoomLevel > previousZoom) {
      const scaleFactor = newZoomLevel / previousZoom;

      setDragState((prevState) => ({
        ...prevState,
        translateX:
          (mouseX - rect.width / 2) * (1 - scaleFactor) + prevState.translateX,
        translateY:
          (mouseY - rect.height / 2) * (1 - scaleFactor) + prevState.translateY,
      }));
    }

    // Reset translation when zooming out
    if (newZoomLevel <= 3) {
      setDragState((prevState) => ({
        ...prevState,
        translateX: 0,
        translateY: 0,
      }));
    }
  };

  const startDrag = (e) => {
    if (e.type === "touchstart") {
      if (e.touches.length === 2) {
        // Pinch start
        const [touch1, touch2] = e.touches;
        const distance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );
        setInitialPinchDistance(distance);
        setPinchZoomStartLevel(zoomLevel);
      } else if (e.touches.length === 1) {
        // Single touch drag
        setDragState((prev) => ({
          ...prev,
          startX: e.touches[0].clientX,
          startY: e.touches[0].clientY,
          prevTranslateX: prev.translateX,
          prevTranslateY: prev.translateY,
        }));
      }
    } else {
      setDragState((prev) => ({
        ...prev,
        isDragging: true,
        startX: e.clientX,
        startY: e.clientY,
        prevTranslateX: prev.translateX,
        prevTranslateY: prev.translateY,
      }));
    }
  };

  const onDrag = (e) => {
    if (e.type === "touchmove") {
      if (e.touches.length === 2) {
        // Pinch zoom
        const [touch1, touch2] = e.touches;
        const distance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );
        const scaleFactor = distance / initialPinchDistance;
        setZoomLevel(Math.min(Math.max(pinchZoomStartLevel * scaleFactor, 0.5), 7));
      } else if (e.touches.length === 1) {
        // Single touch drag
        const deltaX = e.touches[0].clientX - dragState.startX;
        const deltaY = e.touches[0].clientY - dragState.startY;
        setDragState((prev) => ({
          ...prev,
          translateX: prev.prevTranslateX + deltaX,
          translateY: prev.prevTranslateY + deltaY,
        }));
      }
    } else if (dragState.isDragging) {
      const deltaX = e.clientX - dragState.startX;
      const deltaY = e.clientY - dragState.startY;
      setDragState((prev) => ({
        ...prev,
        translateX: prev.prevTranslateX + deltaX,
        translateY: prev.prevTranslateY + deltaY,
      }));
    }
  };

  const endDrag = () => {
    setDragState((prev) => ({
      ...prev,
      isDragging: false,
      touchStartX: null,
      touchStartY: null,
      prevTranslateX: prev.translateX,
      prevTranslateY: prev.translateY,
    }));
  };


  const handleDoubleClick = (e) => {
    if (!selectedImage) return;

    const container = e.target.getBoundingClientRect();
    const clickX = e.clientX - container.left;
    const clickY = e.clientY - container.top;

    // Toggle between zoom levels: zoom in to 3x or reset to 1x
    if (zoomLevel === 1) {
      const newZoomLevel = 7; // Aggressive zoom in
      const scaleFactor = newZoomLevel / zoomLevel;

      const deltaX = clickX - container.width / 2;
      const deltaY = clickY - container.height / 2;

      setZoomLevel(newZoomLevel);
      setDragState((prev) => ({
        ...prev,
        translateX: prev.translateX - deltaX * (scaleFactor - 1),
        translateY: prev.translateY - deltaY * (scaleFactor - 1),
        prevTranslateX: prev.translateX - deltaX * (scaleFactor - 1),
        prevTranslateY: prev.translateY - deltaY * (scaleFactor - 1),
      }));
    } else {
      // Reset to default zoom and position
      resetView();
    }
  };

  return (
    <div className="flex overflow-hidden flex-col justify-center items-center pb-16 bg-gray-50 w-full max-w-full">
      <div className="flex overflow-hidden flex-col justify-center items-center md:px-28 md:py-16 max-w-full bg-[#0D2476] min-h-[220px] md:min-h-[254px] w-full max-md:px-5 max-md:py-8">
        <div className="flex flex-col w-full max-w-[1320px]">
          <div className="text-2xl md:text-4xl font-extrabold text-gray-50 max-md:max-w-full">
            About Profile
          </div>
          <div className="mt-3 text-sm leading-2 md:mt-6 md:text-base md:leading-6 text-white max-md:max-w-full">
            This sample profile showcases what your completed profile will look
            like. Simply follow the prompts provided on the side of the screen;
            <br />
            they will guide you through each step of creating your profile,
            providing helpful tips on what information to include and how to
            best present yourself.
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 md:mt-10 max-w-[1320px] max-md:px-5 max-md:mt-16">
        <div className="flex flex-col max-w-full">
          <div className="flex gap-2 justify-center items-center self-start px-3 py-1.5 text-sm font-medium leading-none text-center text-green-500 bg-green-100 rounded-3xl">
            <HiOutlineLightningBolt className="font-bold" />
            <div className="self-stretch my-auto">Full ID Check Sample Profile</div>
          </div>
          <div className="mt-4 text-xl md:text-2xl font-bold text-gray-900 max-md:max-w-full">
            Profile Setup Guide
          </div>
          <p className="text-gray-500 mt-2">
            Click or tap the image to activate zoom mode. Double-click a specific
            area to zoom into that part of the image. Once zoomed in, use your
            mouse scroll wheel or pinch to zoom further, and click and drag to
            explore the profile details.
          </p>
        </div>
        <img
          loading="lazy"
          src="/FullIDCheck_about_profile.jpg"
          style={{
            transform: isHovered ? "scale(1.02)" : "scale(1)",
            transition: "transform 200ms ease-in-out, box-shadow 200ms ease-in-out",
          }}
          className="mt-8 md:mt-16 object-contain w-full rounded-xl cursor-pointer"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => openImage("/FullIDCheck_about_profile.jpg")}
          alt="Profile Guide"
        />
      </div>
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div
            className="relative flex flex-col items-center justify-center w-full h-full overflow-hidden"
            onWheel={handleWheel}
            onDoubleClick={handleDoubleClick}
          >
            <div
              className="cursor-grab active:cursor-grabbing w-[33%] max-md:w-[90%]"
              style={{
                transform: `translate(${dragState.translateX}px, ${dragState.translateY}px) scale(${zoomLevel})`,
                transition: dragState.isDragging ? "none" : "transform 0.2s ease",
                touchAction: "none",
              }}
              onMouseDown={startDrag}
              onMouseMove={onDrag}
              onMouseUp={endDrag}
              onMouseLeave={endDrag}
              onTouchStart={startDrag}
              onTouchMove={onDrag}
              onTouchEnd={endDrag}
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={selectedImage}
                draggable="false"
                alt="Full View"
                loading="lazy"
                decoding="async"
              />
            </div>
            <button
              className="absolute top-4 right-4 text-white text-3xl max-md:text-lg"
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
              }}
            >
              <RxCross1 />
            </button>
            <div className="absolute rounded bg-[#000000b3] bottom-2 flex p-1 space-x-1" onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
              <button className="py-2.5 px-3 rounded text-white hover:bg-gray-900 text-2xl max-md:text-sm" onClick={zoomOut} title="Zoom Out">
                <FiMinus />
              </button>
              <button className="py-2.5 px-3 rounded text-white hover:bg-gray-900 text-2xl max-md:text-sm" onClick={resetView} title="Reset">
                <BiReset />
              </button>
              <button className="py-2.5 px-3 rounded text-white hover:bg-gray-900 text-2xl max-md:text-sm" onClick={zoomIn} title="Zoom In">
                <FiPlus />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutProfile;
