import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  replyDevregMessage
} from "../../../../api/apiCall";
import ThumbsUpIcon from "../assets/ThumbsUpIcon.svg"
import { MdOutlineOpenInNew } from "react-icons/md";

function ProfileSideBar({ profileInfo }) {
  const token = localStorage.getItem("token");

  const user = JSON.parse(localStorage.getItem("user"));
  const [sending, setSending] = useState(false);
  const [messageSendBtn, setMessageSendBtn] = useState("Send Message");
  const [response, setResponse] = useState({
    status: null,
    message: "",
  });
  const [payloads, setPayloads] = useState({
    fullname: user.fullname,
    email: user.email,
    phone_number: user.phone_number,
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setPayloads({ ...payloads, phone_number: e.target.value });
  };

  const sendMessage = async () => {
    setMessageSendBtn("Sending...")
    setSending(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          receiver_id: profileInfo.id,
          subject: payloads.subject,
          message_content: payloads.message,
          receiver_platform: 'devreg',
        }
      }

      const response = await replyDevregMessage(data);
      if (response.status === 201) {
        setResponse({
          status: true,
          message: "Message sent successfully!",
        })
        setMessageSendBtn("Message Sent")
        setPayloads({
          fullname: user.fullname,
          email: user.email,
          phone_number: user.phone_number,
          subject: "",
          message: "",
        });
      }
    }
    catch (error) {
      console.error("Couldn't send message: ", error);
      setResponse({
        status: false,
        message: error.response?.data?.message,
      })
    }
    finally {
      setSending(false);
      if (!response.status) {
        setMessageSendBtn("Send Message");
        return;
      }
      setTimeout(() => {
        setMessageSendBtn("Send Message");
      }, 3000)
    }
  }

  return (
    <>
      <div className="w-full flex flex-col py-4 text-base leading-6 bg-white rounded-xl text-neutral-400">
        <div className="text-3xl font-semibold leading-8 text-black">
          Want to work with {profileInfo.first_name}?
        </div>

        <input
          className="justify-center py-2 mt-4 rounded-lg border border-solid border-neutral-300 px-3 text-black"
          placeholder="Full Name"
          value={payloads.fullname}
          onChange={(e) => setPayloads({ ...payloads, fullname: e.target.value })}
        />

        <input
          className="justify-center py-2 mt-4 whitespace-nowrap rounded-lg border border-solid border-neutral-300 px-3  text-black"
          placeholder="Email"
          value={payloads.email}
          onChange={(e) => setPayloads({ ...payloads, email: e.target.value })}
        />
        <div className="mt-3">
          <PhoneInput
            country={"au"}
            value={payloads.phone_number}
            onChange={handleChange}
            inputStyle={{
              width: "100%",
              padding: "0.75rem",
              fontSize: "1rem",
              lineHeight: "1.5",
              color: "black",
              backgroundColor: "#fff",
              border: "1px solid #BCBCBC",
              borderRadius: ".4rem",
              paddingLeft: "50px",
              transition:
                "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
            }}
            buttonStyle={{
              backgroundColor: "#fff",
              border: "1px solid #BCBCBC",
              borderRadius: " 0.6rem",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
            }}
          />
        </div>

        <input
          className="justify-center py-2 mt-4 rounded-lg border border-solid border-neutral-300 px-3 text-black"
          placeholder="Subject"
          value={payloads.subject}
          onChange={(e) => setPayloads({ ...payloads, subject: e.target.value })}
        />

        <textarea
          className="justify-center items-start px-2.5 pt-2.5 pb-12 mt-6 text-base leading-5 rounded-lg border border-solid border-neutral-300 text-black max-md:pr-5 max-md:pb-10"
          placeholder="Say something..."
          value={payloads.message}
          onChange={(e) => setPayloads({ ...payloads, message: e.target.value })}
        />

        {response.status !== null && (
          <small className={`${response.status ? 'text-green-500' : 'text-red-500'} mt-2 text-center`}>{response.message}</small>
        )}

        <button
          className={`text-center justify-center px-5 py-2 mt-2 text-white bg-blue-900 rounded-3xl max-md:px-8 cursor-pointer ${sending ? 'cursor-not-allowed opacity-[50%]' : ''} ${messageSendBtn === 'Message Sent' ? 'cursor-not-allowed' : ''}`}
          onClick={sendMessage}
          disabled={sending || messageSendBtn === 'Message Sent'}
          id="send-message-btn"
        >
          {messageSendBtn}
        </button>
        <div className="flex flex-col py-4 mt-4 bg-white rounded-xl">
          <div className="flex flex-col p-4 rounded-xl border border-solid border-neutral-300">
            <div className="text-xl font-bold leading-8 text-blue-900">
              Admin’s Feedback
            </div>

            <div className="flex gap-2 py-1 mt-4 text-base leading-6 text-center rounded-[100px] text-neutral-500">
              <img
                alt="img"
                loading="lazy"
                src={ThumbsUpIcon}
                className="shrink-0 w-6 aspect-square"
              />
              <div>100% Reliable Professional</div>
            </div>
          </div>
          <div className="w-[98%] h-[2px] mt-6 bg-neutral-300 m-auto"></div>
          <div className="justify-center text-center p-2 mt-6 text-base leading-6 text-white bg-blue-900 rounded-3xl max-md:px-5">
            Contact Register Admin
          </div>
          <textarea
            className="justify-center items-start px-2.5 pt-2.5 pb-12 mt-6 text-base leading-5 rounded-xl border border-solid border-neutral-300 text-black max-md:pr-5 max-md:pb-10"
            placeholder="Say something..."
          />
          <div className=" text-center mt-3 cursor-pointer justify-center items-center p-2 text-base leading-6 text-white whitespace-nowrap bg-blue-900 rounded-[100px] max-md:px-5">
            Send
          </div>
          <div className="flex flex-col pb-9 mt-6 w-full text-xl leading-8 bg-white rounded-xl text-neutral-800">
            <div>
              <span className="font-bold text-blue-900">Availability</span>
              <br />
              {profileInfo.availability}
            </div>
            <div className="w-[98%] h-[2px] mt-4 bg-neutral-300 m-auto"></div>
            <div className="mt-4">
              <span className="font-bold text-blue-900">Work Arrangement</span>
              <br />
              {profileInfo.work_setup_preferences}
            </div>

            <div className="w-[98%] h-[2px] mt-4 bg-neutral-300 m-auto"></div>

            <div className="mt-4 font-bold text-blue-900 leading-[157%]">
              Languages
            </div>
            <div className="flex flex-wrap gap-4 content-start mt-2 text-base leading-6 whitespace-nowrap text-neutral-600">
              <div className="flex flex-wrap gap-2">
                {profileInfo.languages?.map((language, index) => (
                  <div className="flex items-center gap-2 py-2 px-3 bg-gray-200 rounded-[100px]" key={index}>
                    <div>{language.language}</div>
                    <MdOutlineOpenInNew />
                  </div>
                ))}

              </div>
            </div>
            <div className="w-[98%] h-[2px] mt-4 bg-neutral-300 m-auto"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileSideBar;
