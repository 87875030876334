import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function RegistrationNavbar() {
  const [showNavLinks, setShowNavLinks] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="w-full border-b border-[#E5E7EB]">
      <header className="max-w-[1366px] m-auto flex justify-between items-center max-md:flex-wrap max-md:max-w-full max-sm:px-2 max-sm:py-2 px-6 py-3">
        <img
          loading="lazy"
          src="/FullIDCheckLogo.svg"
          alt="Company logo"
          className=" my-auto max-w-full w-[220px] cursor-pointer max-sm:w-[170px] max-[378px]:w-[120px]"
          onClick={() => navigate("/")}
        />
        <nav className="max-md:flex-wrap flex flex-row max-sm:hidden max-[900px]:hidden cursor-pointer font-semibold px-4">
          {""}
        </nav>
        <div className="flex max-sm:gap-2 gap-4 min-[900px]:hidden ">
          <div className="self-stretch my-auto text-lg text-blue-900 font-bold min-[900px]:hidden ">
            {""}
          </div>
        </div>
      </header>
    </div>
  );
}

export default RegistrationNavbar;
