import React, { useEffect, useState } from "react";
import useRegistrationForm from "../../api/customHook/useRegistrationForm";
import { CiCircleCheck } from "react-icons/ci";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  HardwareProfessions,
  SoftwareProfessions,
} from "./data/TypeOfJobProfessions";
import PhoneNumber from "./components/PhoneNumber";
import BacktoHomepage from "../Login/components/BackToHomePage";
import Languages from "./data/languages.js";

function Registration() {
  const navigate = useNavigate();
  const {
    applySuccess,
    setCheckboxChecked,
    uniqueJobTypes,
    checkboxChecked,
    selectedOption,
    selectedProfession,
    languages,
    interviewLanguage,
    fullName,
    password,
    confirmPassword,
    isLengthValid,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    passwordsMatch,
    email,
    isValidEmail,
    setFullName,
    handleApply,
    handleSelectChange,
    handleSelectChangeProfession,
    handleChange,
    validateEmail,
    handlePhoneNumberChange,
    errors,
    description,
    selectedJobType,
    professionsForSelectedJobType,
    handleProfessionChange,
    handleJobTypeChange,
    setInterviewLanguage,
  } = useRegistrationForm();
  const [otherProfesion, setOtherProfesion] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Use this to generate new Secret Key for encryption
  // const array = new Uint8Array(32);
  // window.crypto.getRandomValues(array);
  // const secretKey = Array.from(array, byte => ('0' + byte.toString(16)).slice(-2)).join('');
  // console.log('secret: '+secretKey);

  const handleSubmitApply = (event) => {
    event.preventDefault();
    handleApply();
  };

  return (
    <div className="w-full min-h-screen py-16 bg-[#F9FAFB] flex justify-center items-center max-md:px-5">
      <div className="bg-white rounded-xl w-[570px] max-md:w-full p-6 border border-[#E5E7EB]">
        <div className="flex items-center justify-between w-full mb-4">
          <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
            <BacktoHomepage />
          </div>
          <img src="/FullIDCheckLogo.svg" alt="FullIDCheck Logo" className="h-8" />
        </div>
        <hr className="w-full border-t border-gray-200" />
        <div className="text-start mb-8 max-mb:text-center mt-6">
          <h2 className="text-2xl font-bold text-blue-900">Registration</h2>
          <p className="text-gray-600 mt-2 mb-2">
            Create your account by filling in the details below.
          </p>
          <p className="text-gray-500 text-xs mt-1">
            Note: If you are a software or hardware development professional wishing to register and get verified, please visit{" "}
            <a href="https://devreg.org" className="text-devRegBlue">
              devreg.org
            </a>
          </p>
        </div>
        {errors && <small className="text-red-500 flex justify-center">{errors}</small>}
        <form onSubmit={handleSubmitApply} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Full Name
            </label>
            <input
              className="w-full px-4 py-3 rounded-lg border border-gray-300 text-sm"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div style={{ display: 'none' }}>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type of Job
              </label>
              <input
                className="w-full px-4 py-3 rounded-lg border border-gray-300 text-sm"
                placeholder="Type of Job"
                value={selectedJobType}
                onChange={(e) => handleJobTypeChange(e)}
              />
            </div>
            <div className="md:col-span-2"> {/* This makes Profession full-width */}
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Profession
              </label>
              <input
                className="w-full px-4 py-3 rounded-lg border border-gray-300 text-sm"
                placeholder="Profession"
                value={selectedProfession}
                onChange={(e) => handleProfessionChange(e)}
              />
            </div>
          </div>


          {description && (
            <div className="bg-[#F6F6F6] p-2 rounded-lg  mt-2 mb-4 text-[#4B4B4B]">
              <div>
                <div>
                  <p className="text-center ">{description}</p>
                </div>
              </div>
            </div>
          )}

          {selectedProfession === "Others" && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Other Profession
              </label>
              <input
                className="w-full px-4 py-3 rounded-lg border border-gray-300 text-sm"
                placeholder="Other Profession"
                value={otherProfesion}
                onChange={(e) => setOtherProfesion(e.target.value)}
              />
            </div>
          )}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Interview Language
            </label>
            <select
              className="w-full px-4 py-3 rounded-lg border border-gray-300 text-sm"
              placeholder="Interview Language"
              value={interviewLanguage}
              onChange={(e) => setInterviewLanguage(e.target.value)}
            >
              <option value="" disabled selected>
                Select your language for interview
              </option>
              {Languages.map((language, index) => (  // Use the Languages array here
                <option key={index} value={language}>
                  {language}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              className="w-full px-4 py-3 rounded-lg border border-gray-300 text-sm"
              placeholder="Email"
              value={email}
              onChange={validateEmail}
            />
            {!isValidEmail && (
              <div className="text-red-600 text-xs mt-1">
                Please enter a valid email address.
              </div>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone Number
            </label>
            <PhoneNumber onPhoneNumberChange={handlePhoneNumberChange} />
          </div>
          <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <div className="relative">
              <input
                className={`w-full px-4 py-3 rounded-lg border border-gray-300 text-sm ${!passwordsMatch ? "border-red-600" : ""
                  }`}
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={password}
                onChange={handleChange}
              />
              <div
                className="absolute inset-y-0 right-4 flex items-center cursor-pointer text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
              </div>
            </div>
            <div className="text-xs text-gray-600 mt-2">
              <ul className="space-y-1 flex flex-col items-start">
                <li className={`flex items-center gap-1 ${isLengthValid ? "text-green-600" : ""}`}>
                  {isLengthValid ? (
                    <CiCircleCheck size={16} />
                  ) : (
                    <MdOutlineCircle size={16} className="text-red-600" />
                  )}{" "}
                  Be at least 8 characters in length
                </li>
                <li
                  className={`flex items-center gap-1 ${hasUpperCase && hasLowerCase ? "text-green-600" : ""
                    }`}
                >
                  {hasUpperCase && hasLowerCase ? (
                    <CiCircleCheck size={16} />
                  ) : (
                    <MdOutlineCircle size={16} className="text-red-600" />
                  )}{" "}
                  Contain both upper and lowercase alphabetic<br className="hidden max-md:block" />  characters (e.g. A-Z,
                  a-z)
                </li>
                <li className={`flex items-center gap-1 ${hasNumber ? "text-green-600" : ""}`}>
                  {hasNumber ? (
                    <CiCircleCheck size={16} />
                  ) : (
                    <MdOutlineCircle size={16} className="text-red-600" />
                  )}{" "}
                  Have at least one numerical character (e.g. 0-9)
                </li>
                <li className={`flex items-center gap-1 ${hasSpecialChar ? "text-green-600" : ""}`}>
                  {hasSpecialChar ? (
                    <CiCircleCheck size={16} />
                  ) : (
                    <MdOutlineCircle size={16} className="text-red-600" />
                  )}{" "}
                  Have at least one special character<br className="hidden max-md:block" />  (e.g. ~!@#$%^&*()_-+=)
                </li>
              </ul>
            </div>
          </div>
          <div className="relative mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Confirm Password
            </label>
            <div className="relative">
              <input
                className={`w-full px-4 py-3 rounded-lg border border-gray-300 text-sm ${!passwordsMatch ? "border-red-600" : ""
                  }`}
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleChange}
                disabled={
                  !isLengthValid ||
                  !hasUpperCase ||
                  !hasLowerCase ||
                  !hasNumber ||
                  !hasSpecialChar
                }
              />
              <div
                className="absolute inset-y-0 right-4 flex items-center cursor-pointer text-gray-500"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
              </div>
            </div>
            {!passwordsMatch && confirmPassword && (
              <small className="text-red-600 mt-2 ml-1">Passwords do not match</small>
            )}
          </div>
          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              checked={checkboxChecked}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded"
              onChange={() => {
                setCheckboxChecked(!checkboxChecked);
              }}
            />
            <label className="ml-2 text-sm text-gray-600">
              I have read and accept the{" "}
              <span
                className="text-blue-700 cursor-pointer"
                onClick={() => window.open("/terms-and-conditions", "_blank")}
              >
                terms and conditions
              </span>
            </label>
          </div>

          <button
            type="submit"
            onClick={handleSubmitApply}
            className={`w-full mt-6 px-4 py-3 font-normal text-white bg-blue-900 hover:bg-blue-800 rounded-full ${!passwordsMatch || !checkboxChecked || applySuccess ? 'cursor-not-allowed opacity-[50%]' : 'cursor-pointer'}`}
            disabled={!passwordsMatch || !checkboxChecked || applySuccess}
          >
            Apply
          </button>
          {applySuccess && (
            <p className="text-center text-green-600 mt-4">
              Your application was successful!
            </p>
          )}
        </form>
        <div className="text-center mt-4">
          <p className="text-gray-600 text-sm">
            Already have an account?{" "}
            <span
              className="text-blue-700 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Log-in
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Registration;