import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomeMain from "../pages/Basic/Home";
import WhyRegisterMain from "../pages/Basic/WhyRegister";
import BenefitPageMain from "../pages/Basic/BenefitPage";
import NavigationBar from "../component/NavBar";
import Footer from "../component/Footer";
import HowItWorks from "../pages/Basic/HowItWorks";
import AboutUs from "../pages/Basic/AboutUs";
import AboutProfile from "../pages/Basic/AboutProfile";
import FAQMain from "../pages/Basic/Faq";
import CategoryFaqs from "../pages/Basic/CategoryFaqs";
import Viewseemore from "../pages/Basic/Viewseemore";
import ContactMain from "../pages/Basic/Contact";
import RequirementsManagerPos from "../pages/Basic/RequirementsManagerPos";
import PrivacyPolicyMain from "../pages/Basic/Privacy";
import AntibribePolicyMain from "../pages/Basic/AntibribePolicy";
// import TermsConditionsMain from "../pages/Basic/TermsConditions/TermsConditionsMain";
import TermsConditionsMain from "../pages/Basic/TermsAndConditions";
import LoginMain from "../pages/Login/LoginMain";
import ForgotPassMain from "../pages/Login/forgotPassword/ForgotPassMain";
import PasswordSuccess from "../pages/Login/forgotPassword/PassUpdateSuccess";

import QRScan from "../pages/Login/2fa/QRScan";
import OTPVerify from "../pages/Login/2fa/OTPVerify";
import RecoveryCodes from "../pages/Login/2fa/RecoveryCodes";
import TwoFAFromApp from "../pages/Login/2fa/TwoFAFromApp";
import EnterRecoveryCodes from "../pages/Login/2fa/EnterRecoveryCodes";
import EnterCodeAccess from "../pages/Login/accessKey/EnterCodeAccess";
import UploadDocumentsReq from "../pages/Login/accessKey/UploadDocumentsReq";
import UploadSuccess from "../pages/Login/accessKey/UploadSuccess";
import Registration from "../pages/Registration/Registration";
import PersonalInformation from "../pages/Registration/PersonalInformation";
import Stripe from "../pages/Registration/Stripe";
import Thankyou from "../pages/Registration/Thankyou";
import UploadDocuments from "../pages/Registration/UploadDocuments";
import FacialRecognition from "../pages/Registration/FacialRecognition";
import Interview from "../pages/Registration/Interview";
import RegistrationSuccess from "../pages/Registration/RegistrationSuccess";
import RegistrationNavbar from "../pages/Registration/components/RegistrationNavbar";
import GuardianInformation from "../pages/Registration/minorApplicant/GuardianInformation";
// import PaymentMethod from "../pages/Registration/minorApplicant/PaymentMethod";
import FacialRecognitionApproved from "../pages/Registration/minorApplicant/FacialRecognitionApproved";
import ScanFace from "../pages/Registration/FaceScanner/ScanFace";
import EmailVerified from "../pages/Registration/EmailVerified";
import EmailVerifiedFailed from "../pages/Registration/EmailVerifiedFailed";
import PageNotFound from "../../src/PageNotFound";
import RegistrationDone from "../pages/Registration/RegistrationDone";
import InterviewProcess from "../pages/Registration/InterviewProcess";
import GoogleAuthCallback from "../pages/Registration/GoogleAuthCallback";

import axios from "axios";

function NotLoggedInNavigations() {
  const [pageToLogin, setPageToLogin] = useState(false);
  const [showRegistrationNav, setShowRegistrationNav] = useState(false);
  const location = useLocation();

  const loginPaths = [
    "/login",
    "/register",
    "/forgot-password",
    "/qr-scan",
    "/otp-verify",
    "/recovery-codes",
    "/2fa-authApp",
    "/enter-recovery-codes",
    "/enter-access-key",
    "/password-success",
  ];

  const registrationNavPaths = [
    "/payment",
    "/thankyou",
    "/upload-documentsreq",
    "/upload-success",
    "/upload-documents",
    "/face-recognition",
    "/interview-process",
    "/interview",
    "/registration-done",
    "/guardian-information",
    "/registration-success",
    "/personal-information",
    // "/payment-method",
    "/face-recognition-minor",
    "/email-verified",
    "/email-verification/failed",
  ];

  useEffect(() => {
    const isLoginPage = loginPaths.includes(location.pathname);
    const isRegistrationNavPage = registrationNavPaths.includes(location.pathname);
    const isScanFacePage = location.pathname.startsWith("/face-scan/");

    if (isLoginPage || isRegistrationNavPage || isScanFacePage) {
      setPageToLogin(true);
      setShowRegistrationNav(isRegistrationNavPage && !isScanFacePage);
    } else {
      setPageToLogin(false);
      setShowRegistrationNav(false);
    }
  }, [location]);


  const [loading, setLoading] = useState({ members: false });
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    setLoading((prev) => ({ ...prev, members: true }));
    handleGetTeamMembers();
  }, [])

  const handleGetTeamMembers = async () => {
    try {

      const [response1, response2] = await Promise.all([
        axios.get(`${process.env.REACT_APP_CEO_API_URL}/api/country-manager`),
        axios.get(`${process.env.REACT_APP_CEO_API_URL}/api/senior-country-manager`)
      ]);

      if (response1.status === 200 && response2.status === 200) {
        const countryManagers = response1.data.map((item) => ({
          ...item,
          fullname: `${item.first_name} ${item.middle_name} ${item.last_name}`,
          position: "Country Manager",
        }));
        const seniorCountryManagers = response2.data.map((item) => ({
          ...item,
          fullname: `${item.first_name} ${item.middle_name} ${item.last_name}`,
          position: "Senior Country Manager",
        }));

        setTeamMembers([
          ...countryManagers,
          ...seniorCountryManagers,
        ]);
      }
    } catch (error) {
      console.error("Failed to fetch team members: ", error);
    } finally {
      setLoading((prev) => ({ ...prev, members: false }));
    }
  };

  return (
    <>
      {!pageToLogin && <NavigationBar />}
      {showRegistrationNav && <RegistrationNavbar />}
      <Routes>
        <Route path="/" element={<HomeMain />} />
        <Route path="/why-register" element={<WhyRegisterMain />} />
        <Route path="/benefits" element={<BenefitPageMain />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/about-us" element={<AboutUs teamMembers={teamMembers} loading={loading.members} />} />
        <Route path="/about-profile" element={<AboutProfile />} />
        <Route path="/faq" element={<FAQMain />} />
        <Route path="/faq/category" element={<CategoryFaqs />} />
        <Route path="/faq/category/view" element={<Viewseemore />} />
        <Route path="/contact" element={<ContactMain />} />
        <Route path="/apply-country-manager" element={<RequirementsManagerPos />} />
        <Route path="/privacy" element={<PrivacyPolicyMain />} />
        <Route path="/anti-bribery-policy" element={<AntibribePolicyMain />} />
        <Route path="/login" element={<LoginMain />} />
        <Route path="/forgot-password" element={<ForgotPassMain />} />
        <Route path="/password-success" element={<PasswordSuccess />} />

        <Route path="/qr-scan" element={<QRScan />} />
        <Route path="/otp-verify" element={<OTPVerify />} />
        <Route path="/recovery-codes" element={<RecoveryCodes />} />
        <Route path="/2fa-authApp" element={<TwoFAFromApp />} />
        <Route path="/enter-recovery-codes" element={<EnterRecoveryCodes />} />
        <Route path="/enter-access-key" element={<EnterCodeAccess />} />
        <Route path="/upload-documentsreq" element={<UploadDocumentsReq />} />
        <Route path="/upload-success" element={<UploadSuccess />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/email-verification/failed" element={<EmailVerifiedFailed />} />
        <Route path="/personal-information" element={<PersonalInformation />} />
        <Route path="/payment" element={<Stripe />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/upload-documents" element={<UploadDocuments />} />
        <Route path="/face-recognition" element={<FacialRecognition />} />
        <Route path="/face-scan/:key" element={<ScanFace />} />
        <Route path="/interview-process" element={<InterviewProcess />} />
        <Route path="/interview" element={<Interview />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} />
        <Route path="/registration-done" element={<RegistrationDone />} />
        <Route path="/guardian-information" element={<GuardianInformation />} />
        {/* <Route path="/payment-method" element={<PaymentMethod />} /> */}
        <Route path="/face-recognition-minor" element={<FacialRecognitionApproved />} />
        <Route path="/terms-and-conditions" element={<TermsConditionsMain />} />
        <Route path="/auth/callback" element={<GoogleAuthCallback />} />
        {/* Wildcard route for handling 404 - Page Not Found */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {!pageToLogin && <Footer />}
    </>
  );
}

export default NotLoggedInNavigations;

