import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import MessagesMain from "../pages/Dashboard/Messages/MessagesMain";
import DashboardMain from "../pages/Dashboard/MainDashboard/DashboardMain";
import DashboardNavbar from "../pages/Dashboard/components/DashboardNavbar";
import SidebarDashboard from "../pages/Dashboard/components/SidebarDashboard";
import FooterDashboard from "../pages/Dashboard/components/FooterDashboard";
import EarningsAndReferrals from "../pages/Dashboard/EarningsAndReferrals/EarningsAndReferrals";
import Certificate from "../pages/Dashboard/Certificate/Certificate";
import EditProfile from "../pages/Dashboard/EditProfile/EditProfileMain";
import ProfileMain from "../pages/Dashboard/EditProfile/pages/ProfileMain";

// OLD FORUM
import Forum from "../pages/Dashboard/Forum/pages/MainForum/MainForum";
import SavedPostsMain from "../pages/Dashboard/Forum/pages/SavedPosts/SavedPostsMain";
import NewPost from "../pages/Dashboard/Forum/pages/NewPost/NewPostMain";
import TopViewed from "../pages/Dashboard/Forum/pages/TopViewed/TopViewedMain";

import AccountSettingsMain from "../pages/Dashboard/AccountSetting/AccountSettingsMain";
import ChangePassword from "../pages/Dashboard/AccountSetting/ChangePassword/ChangePassword";
import ChangeNumber from "../pages/Dashboard/AccountSetting/ChangeNumber/ChangeNumber";
import ChangeEmail from "../pages/Dashboard/AccountSetting/ChangeEmail/ChangeEmail";
import Help from "../pages/Dashboard/AccountSetting/Help";
import Ticketing from "../pages/Dashboard/AccountSetting/Ticketing";
import PageNotFound from "../../src/PageNotFound"; // Assuming this is your Page Not Found component

function LoggedIn() {
  const location = useLocation();
  const [hideSidebar, setHideSidebar] = useState(false);

  useEffect(() => {
    if (
      location.pathname === "/dashboard/profile" ||
      location.pathname === "/dashboard/profile/" ||
      location.pathname === "/dashboard/forum" ||
      location.pathname === "/dashboard/forum/" ||
      location.pathname === "/dashboard/forum/category-viewed" ||
      location.pathname === "/dashboard/forum/category-viewed/" ||
      location.pathname.startsWith("/dashboard/forum/post/") ||
      location.pathname === "/dashboard/forum/new-post" ||
      location.pathname === "/dashboard/forum/new-post/" ||
      location.pathname === "/dashboard/forum/saved-posts" ||
      location.pathname === "/dashboard/forum/saved-posts/"
    ) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }
  }, [location]);

  return (
    <>
      <DashboardNavbar />
      <div className="flex max-w-main m-auto">
        {!hideSidebar && <SidebarDashboard />}
        <div className="w-full pr-5 pl-8 max-[1024px]:pl-5 max-sm:pr-4 max-sm:p-4">
          <Routes>
            <Route path="/dashboard" element={<DashboardMain />} />
            <Route path="/dashboard/messages" element={<MessagesMain />} />
            <Route
              path="/dashboard/earnings-and-referrals"
              element={<EarningsAndReferrals />}
            />
            <Route path="/dashboard/certificate" element={<Certificate />} />
            <Route path="/dashboard/profile" element={<ProfileMain />} />
            <Route path="/dashboard/forum" element={<Forum />} />
            <Route path="/dashboard/forum/post/:id" element={<TopViewed />} />
            <Route
              path="/dashboard/forum/saved-posts"
              element={<SavedPostsMain />}
            />
            <Route path="/dashboard/forum/new-post" element={<NewPost />} />
            {/* 
            <Route path="/dashboard/forum" element={<MainForum />} />
            <Route path="/dashboard/forum/category-viewed" element={<CategoryViewed />} />
            <Route path="/dashboard/forum/post/:id" element={<TopViewedMain />} />
            <Route path="/dashboard/forum/saved-posts" element={<SavedPosts />} />
            <Route path="/dashboard/forum/new-post" element={<CreatePost />} />
 */}
            <Route path="/dashboard/edit-profile" element={<EditProfile />} />
            <Route path="/dashboard/help" element={<Help />} />

            <Route path="/dashboard/ticketing" element={<Ticketing />} />
            <Route
              path="/dashboard/account-settings/change-number"
              element={<ChangeNumber />}
            />
            <Route
              path="/dashboard/account-settings/change-password"
              element={<ChangePassword />}
            />
            <Route
              path="/dashboard/account-settings/change-email"
              element={<ChangeEmail />}
            />
            <Route
              path="/dashboard/account-settings"
              element={<AccountSettingsMain />}
            />
            {/* Catch-all route for Page Not Found */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
      <FooterDashboard />
    </>
  );
}

export default LoggedIn;
