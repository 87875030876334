import React from "react";
import TopBanner from "../../component/TopBanner";
import BecomeRecognised from "../../component/BecomeRecognised";

function AntibribePolicy() {

  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      <TopBanner bannerImg={"/Anti-Bribery-banner.png"} />
      <div className="self-center mt-16 w-full max-w-[1366px] max-md:mt-20 max-md:max-w-full m-auto pb-16">
        <div className="m-auto px-5">
          <div className="mt-16 text-3xl max-md:text-[22px] font-bold tracking-tight leading-9 text-blue-900 max-md:mt-10 max-md:max-w-full">
            Our Anti-Bribery and Corruption Policy
          </div>
          {/* section-2 */}
          <div className="flex flex-col px-5 max-md:px-0 py-12 max-md:py-5 mb-10 mt-4 w-full bg-white border border-solid rounded-xl max-w-[1307px] max-md:max-w-full">
            <div className="flex flex-col px-5 pb-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                1. Introduction
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                1.1 Definitions
                <br />
                In this policy, the terms "we", "us," and "our" are synonymous with Global ID Verification Pty Ltd. The terms "team members" and "associates" encompass a broad range of individuals working under our direction, including but not limited to employees, contractors, agents, and interns. "Representatives" refer to any external parties conducting business either for us or on behalf of another entity, which includes a diverse group such as clients, contractors, subcontractors, suppliers, consultants, legal professionals, accountants, lobbyists, sales representatives, brokers, and franchise owners.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                1.2 Purpose and Reach
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                Global ID Verification Pty Ltd commits to a stringent Anti-Bribery and Corruption (AB&C) stance, ensuring zero tolerance. This policy delineates the essential principles of our AB&C framework, which is designed to prevent, identify, and tackle any Bribery and Corruption risks, while aligning with AB&C regulations in our areas of operation. It is applicable across all levels of our organization, including Directors (both Executive and Non-Executive), Employees, and any Third Parties engaged in activities on our behalf. This policy governs behaviors and activities connected to employment or representation of Global ID Verification Pty Ltd, irrespective of geographical location. It also encompasses all entities within our group, especially those with delegated operational responsibilities. In scenarios where local or international laws, regulatory norms, or contractual commitments clash with this policy, adherence to the stricter regulation is mandated, ensuring no violation of local laws.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                1.3 Roles and Responsibilities
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                The responsibility for the execution and management of this policy primarily lies with the Senior Executive Team, except where specified otherwise. All individuals associated with Global ID Verification Pty Ltd, including Employees, Directors, and Third Parties, are expected to be fully conversant with and adhere to the AB&C obligations. Managers and supervisors have a particular responsibility to ensure these practices are implemented within their domains, and that all team members under their supervision are equally informed and compliant.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                2. Policy Overview
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                Our commitment to ethical business practices involves an unequivocal opposition to all forms of bribery and corruption. We strive to conduct our business dealings with the utmost honesty, professionalism, and integrity. This commitment extends to prohibiting inappropriate promises, gifts, or excessive hospitality to Foreign Public Officials for obtaining any unjust advantage or benefit. We also resist any attempts by external parties, including suppliers and clients, to improperly influence any decision-making processes for undue gains. Our staff, representing the ethos of Global ID Verification Pty Ltd, are obliged to uphold these principles at all times.
                <br />
                Political contributions are not endorsed, and facilitation payments are discouraged as a business method. However, we support making charitable donations and sponsorships, provided they align with ethical standards and comply with local legal frameworks. We also expect our business partners and Representatives to implement robust systems to counteract bribery. In compliance with Australian legal requirements, we commit to documenting and reporting any instances of legal breaches or policy violations.
              </div>
            </div>
            <div className="self-start mt-4 ml-5 text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:ml-2.5">
              3. Due Diligence on Agents
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                3.1 Information Collection
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                Prior to establishing any relationships with agents, our staff are mandated to conduct comprehensive and documented AB&C due diligence. This process involves gathering substantial information to ensure thorough vetting, including:
                <ul className="list-disc ml-5">
                  <li>
                    Understanding the context of the agent's introduction, including referrals, the circumstances of the introduction, and the relevance of their skill set or services to our organization.
                  </li>
                  <li>
                    Investigating the ultimate beneficial owners of the agent and
                    their accountability.
                  </li>
                  <li>
                    Determining if the agent holds any position as a Foreign Public
                    Official and understanding the implications thereof.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                3.2 Documentation of Agency Agreements
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                <span className="font-semibold">3.2.1 Essential Criteria</span>
                <br />
                It is mandatory for our staff to clearly document, in writing, the agreements made with agents. This documentation should minimally include:
                <ul className="list-disc ml-5">
                  <li>
                    Acknowledgement of and agreement to adhere to our policies
                    and intentions.
                  </li>
                  <li>
                    Commitment to assist in fulfilling due diligence
                    obligations.
                  </li>
                  <li>
                    Detailed description of the scope of work, accounting
                    procedures, payment and expenses, training requirements,
                    record-keeping norms.
                  </li>
                  <li>
                    Clauses detailing agent accountability and liability, our
                    indemnification rights, and the conditions for contract
                    termination.
                  </li>
                  <li>
                    Agreement for periodic audits by either our organization or
                    an independent auditor.
                  </li>
                </ul>
                <span className="font-semibold">3.2.2 Legal Consultation</span>
                <br />
                Where necessary, our team is encouraged to seek and document
                independent legal advice to ensure the thoroughness and legality
                of the agency agreement.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                3.3 Consideration of Payments to Agents
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                Our staff must exercise due diligence before authorizing any
                payments to agents. This involves:
                <ul className="list-disc ml-5">
                  <li>
                    Understanding the relationship between commissions and the
                    business transaction.
                  </li>
                  <li>
                    Documenting the rationale behind all proposed commissions,
                    ensuring they are reasonable and align with local practices.
                  </li>
                  <li>
                    Verifying that all expenses are justified, legitimate, and
                    properly recorded.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                3.4 Monitoring and Identifying Red Flags
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                Continuous monitoring of agents' activities and behaviors is
                crucial, with immediate reporting of any red flags to our Chief
                Compliance and Integrity Officer (CCIO). Key red flags include:
                <ul className="list-disc ml-5">
                  <li>
                    Operations in high-corruption risk areas or sectors.
                  </li>
                  <li>
                    Engagements requiring interactions with Foreign Public
                    Officials.
                  </li>
                  <li>
                    Incomplete or inaccurate documentation of payments.
                  </li>
                  <li>
                    Luxurious or extravagant settings for meetings with Foreign
                    Public Officials.
                  </li>
                  <li>
                    Requests for political donations or secrecy in transactions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                4. Due Diligence on Corporate Social Responsibility (CSR)
                Programs
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                We recognize the potential for CSR initiatives to be
                misinterpreted as bribery, particularly when they involve
                government or community goodwill-building. Hence, we conduct
                thorough and proportionate AB&C due diligence on any CSR
                programs prior to engagement. This includes a comprehensive
                assessment of the beneficiary, including research of independent
                sources and a series of meetings and interviews. Essential
                considerations include the context of the program, legal status
                of the beneficiary, historical background, board member
                scrutiny, and budget transparency.
                <br />
                Ongoing measures include monitoring the beneficiary's use of
                funds, requiring multiple management approvals for payments,
                setting cash limits, and demanding periodic written reports from
                the recipient.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                5. Speaking Up and Whistleblowing
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                We encourage a culture where team members can freely report any
                suspicions or concerns related to bribery and corruption without
                fear of retaliation. This includes educating our staff about the
                reporting procedures, their right to anonymity, and the support
                mechanisms in place for whistleblowers. All reported allegations
                are to be promptly addressed through the appropriate channels,
                as outlined in our reporting table (Table 5.1).
                <br />
                Our whistleblowing policy is proactive and supportive,
                emphasizing the importance of ethical reporting and safeguarding
                whistleblowers against any form of reprisal. We advise anonymous
                reporting and stress that personal investigations or contact
                with the subject of the complaint should be avoided. Our
                commitment extends to protecting the rights and identities of
                those reporting concerns, with strict actions against anyone
                inhibiting the whistleblowing process.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                6. Hospitality, Gift Giving, Sponsored Travel, and Entertainment
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                Our policy educates employees on acceptable practices regarding
                hospitality, gift giving, sponsored travel, and entertainment.
                Employees are required to report any offers of such nature
                immediately to their managers. Gifts, sponsored travel, or
                entertainment provided to external parties must have prior
                consent. We expect our employees to exercise reasonable judgment
                in these matters, with all gifts being documented and, if
                necessary, subject to further investigation.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                7. Conflicts of Interest, Solicitation, and Extortion
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                All potential conflicts of interest must be disclosed by
                employees at the start of their employment and as they arise.
                Annual updates or renewals of these declarations are required,
                either on the employment anniversary or aligned with the
                financial or calendar year. Prompt reporting of conflicts to the
                CEO and CCIO is mandatory.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                8. Breach of Policy
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                We are committed to addressing any breaches of this policy
                effectively, utilizing appropriate disciplinary or contractual
                processes.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                9. Record Keeping
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                Records supporting this policy are maintained as per the Change
                History section. For earlier versions or inquiries, contact the
                Compliance Team or email info@4bmcorp.com.
              </div>
            </div>
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                Glossary
              </div>
              <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                The glossary section defines key terms such as Beneficial Owner,
                Benefit, Bribery, Eligible Whistleblower, Foreign Public
                Official, Facilitation Payment, and Routine Government Action,
                providing clarity on the concepts integral to this policy.
              </div>
            </div>

            {/* section-3 */}
            <div className="flex flex-col px-5 pb-4 mt-4 max-md:max-w-full">
              <div className="text-2xl font-semibold tracking-tight leading-7 text-indigo-950 max-md:max-w-full">
                References
              </div>
              <div className="mt-4 text-base leading-8  text-zinc-600 max-md:max-w-full">
                The policy concludes with a references section, guiding readers to
                additional resources and legal texts pertinent to the AB&C
                framework.
                <ol className="list-decimal ml-5">
                  <li>
                    <span className="font-bold">
                      Institute of Business Ethics (IBE):
                    </span>{" "}
                    The IBE offers standards and frameworks for implementing an
                    AB&C process, including due diligence for recruitment,
                    anti-bribery terms in contracts, financial controls, reporting
                    mechanisms, and maintaining accurate records​{" "}
                    <a
                      href="https://www.ibe.org.uk/resource/anti-bribery-corruption-abc-standards-and-frameworks.html"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.ibe.org.uk/resource/anti-bribery-corruption-abc-standards-and-frameworks.html
                    </a>
                  </li>
                  <li>
                    <span className="font-bold">
                      Transparency International's Business Principles for
                      Countering Bribery:
                    </span>{" "}
                    This tool assists enterprises in developing practical
                    approaches to countering bribery in all their activities. It
                    includes multi-stakeholder input and covers areas such as
                    bribes, political contributions, and facilitation payments.​{" "}
                    <a
                      href="https://www.ibe.org.uk/resource/anti-bribery-corruption-abc-standards-and-frameworks.html"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.ibe.org.uk/resource/anti-bribery-corruption-abc-standards-and-frameworks.html
                    </a>
                  </li>
                  <li>
                    <span className="font-bold">
                      Good Corporation's Framework on Bribery and Corruption:{" "}
                    </span>
                    This framework sets out management practices to avoid corrupt
                    activities and includes top-level commitment, communication,
                    training, risk assessments, and compliance functions ​{" "}
                    <a
                      href="https://www.ibe.org.uk/resource/anti-bribery-corruption-abc-standards-and-frameworks.html"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.ibe.org.uk/resource/anti-bribery-corruption-abc-standards-and-frameworks.html
                    </a>
                  </li>
                  <li>
                    <span className="font-bold">
                      International Chamber of Commerce (ICC) Rules on
                      Combating Corruption:{" "}
                    </span>
                    These rules provide a method of self-regulation against
                    applicable national laws and international anti-corruption
                    initiatives. The rules cover policies and practices in areas
                    such as bribery and extortion prohibition, third-party
                    interactions, and financial recording.​{" "}
                    <a
                      href="https://www.ibe.org.uk/resource/anti-bribery-corruption-abc-standards-and-frameworks.html"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.ibe.org.uk/resource/anti-bribery-corruption-abc-standards-and-frameworks.html
                    </a>
                  </li>
                  <li>
                    <span className="font-bold">ISO 37001:</span> While not a
                    law, ISO 37001 sets standards for anti-bribery management
                    systems and is integral to achieving compliance. It includes
                    adopting anti-bribery policies, appointing responsible
                    personnel, and conducting due diligence.​{" "}
                    <a
                      href="https://www.diligent.com/resources/blog/anti-bribery-and-corruption"
                      className="underline "
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.diligent.com/resources/blog/anti-bribery-and-corruption
                    </a>
                  </li>
                  <li>
                    <span className="font-bold">
                      UK Bribery Act and OECD Anti-Bribery Convention:
                    </span>{" "}
                    These are influential ABAC laws, with the UK Bribery Act
                    covering both the public and private sectors, and the OECD
                    Convention focusing on the bribery of foreign public
                    officials​.{" "}
                    <a
                      href="https://www.diligent.com/resources/blog/anti-bribery-and-corruption"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.diligent.com/resources/blog/anti-bribery-and-corruption
                    </a>
                  </li>
                  <li>
                    <span className="font-bold">
                      United States Foreign Corrupt Practices Act (FCPA):
                    </span>{" "}
                    This Act prevents organizations from bribing foreign officials
                    and requires publicly traded companies to document their
                    internal accounting controls​.{" "}
                    <a
                      href="https://www.diligent.com/resources/blog/anti-bribery-and-corruption"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.diligent.com/resources/blog/anti-bribery-and-corruption
                    </a>
                  </li>
                  <li>
                    <span className="font-bold">
                      Linklaters' Global Guide:
                    </span>{" "}
                    Anti-bribery and Corruption Law and Enforcement: This guide
                    provides insights into the global landscape of anti-bribery
                    and corruption laws and enforcement, highlighting key themes
                    such as expanding regulation, increased emphasis on
                    self-regulation, and corporate compliance​.{" "}
                    <a
                      href="https://www.linklaters.com/en-us/insights/publications/antibribery-and-corruption/a-review-of-anti-bribery-and-corruption-law-and-enforcement-across-the-globe"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.linklaters.com/en-us/insights/publications/antibribery-and-corruption/a-review-of-anti-bribery-and-corruption-law-and-enforcement-across-the-globe
                    </a>
                  </li>
                </ol>
                <br />
                <span className="font-bold">Disclaimer:</span>
                <span>
                  The information provided on this page, including links to
                  external resources and legal texts, is for general informational
                  purposes only and should not be considered as legal advice.
                  Blockchain Management Corporation Pty Ltd does not guarantee any
                  information on these external websites' accuracy, relevance,
                  timeliness, or completeness. The inclusion of these links does
                  not imply endorsement of the content by Blockchain Management
                  Corporation Pty Ltd. We encourage users to consult with
                  qualified professionals for specific advice tailored to their
                  situation.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <BecomeRecognised
            bgColor={"blue"}
            btnText={"Register Now!"}
            btnColor={true}
          />
        </div>
      </div>
    </div>
  );
}

export default AntibribePolicy;
