import * as React from "react";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa6";

function Footer() {
    const navigate = useNavigate();

    const handleClick = (to) => {
        navigate(to);
        window.scrollTo(0, 0);
    };
    return (
        <div className="flex overflow-hidden flex-col items-center px-32 bg-white border-t border-neutral-300 max-md:px-5">
            <div className="flex overflow-hidden flex-col py-8 w-[1366px] max-[1200px]:w-full">
                <div className="flex flex-col items-center w-full max-md:max-w-full">
                    <div className="flex flex-col max-w-full w-[240px] cursor-pointer">
                        <img
                            loading="lazy"
                            src="/FullIDCheckLogoFooter.svg"
                            className="object-contain w-full"
                            onClick={() => {
                                navigate("/");
                                window.scrollTo(0, 0);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-col justify-center mt-8 w-full text-sm text-gray-500 max-[1200px]:max-w-full">
                    <div className="flex flex-wrap gap-6 justify-center items-end w-full max-[1200px]:max-w-full">
                        <div onClick={() => handleClick('/why-register')} className="cursor-pointer">Why Register?</div>
                        <div onClick={() => handleClick('/benefits')} className="cursor-pointer">Benefit Page</div>
                        <div onClick={() => handleClick('/about-profile')} className="cursor-pointer">About Profile</div>
                        <div onClick={() => handleClick('/how-it-works')} className="cursor-pointer">How It Works</div>
                        <div onClick={() => handleClick('/about-us')} className="cursor-pointer">About Us</div>
                        <div onClick={() => handleClick('/faq')} className="cursor-pointer">FAQ</div>
                        <div onClick={() => handleClick('/contact')} className="cursor-pointer">Contact Us</div>
                    </div>
                </div>
                <div className="flex flex-col mt-8 w-full max-md:max-w-full">
                    <div className="flex gap-6 items-start self-start max-[1200px]:self-center max-sm:self-center max-sm:ml-px">
                        <a href="#" aria-label="Facebook"><FaFacebook size={20} className="text-gray-300" /></a>
                        <a href="#" aria-label="Twitter"><FaTwitter size={20} className="text-gray-300" /></a>
                        <a href="#" aria-label="Instagram"><FaInstagram size={20} className="text-gray-300" /></a>
                        <a href="#" aria-label="LinkedIn"><FaLinkedin size={20} className="text-gray-300" /></a>
                    </div>
                    <div className="flex flex-wrap gap-10 justify-between items-center pt-4 mt-4 w-full text-sm text-gray-500 border-t border-solid min-h-[52px] max-md:max-w-full">
                        <div className="flex gap-4 items-center self-stretch my-auto min-w-[240px] max-[1200px]:mx-auto max-[1200px]:justify-center">
                            <div onClick={() => handleClick('/terms-and-conditions')} className="self-stretch my-auto max-sm:mx-auto max-sm:text-center cursor-pointer">
                                Terms of Service
                            </div>
                            <div onClick={() => handleClick('/anti-bribery-policy')} className="self-stretch my-auto max-sm:mx-auto max-sm:text-center cursor-pointer">
                                Anti-Bribery & Corruption
                            </div>
                            <div onClick={() => handleClick('/privacy')} className="self-stretch my-auto max-sm:mx-auto max-sm:text-center cursor-pointer">
                                Privacy Policy
                            </div>
                        </div>
                        <div className="gap-2 my-auto min-w-[240px] w-[436px] self-stretch max-[1200px]:w-full text-end max-[1200px]:text-center">
                            © 2024 Full ID Check All Rights Reserved
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;