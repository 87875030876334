import React from "react";
import { useNavigate } from "react-router-dom";
import BacktoHomepage from "../components/BackToHomePage";
import { FaCircleCheck } from "react-icons/fa6";

function PassUpdateSuccess() {
  const navigate = useNavigate();
  const redirectToLogin = () => {
    navigate("/login");
  };
  return (
    <div className="flex flex-col items-center px-20 py-20 text-base bg-[#F9FAFB] flex-grow max-md:px-5">
      <div className="flex flex-col p-6 mt-6 max-w-full font-semibold bg-white border border-gray-200 rounded-2xl shadow-sm w-[400px] max-md:w-full max-md:px-5">
        <div className="flex items-center justify-between w-full mb-4">
          <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
            <BacktoHomepage />
          </div>
          <img src="/FullIDCheckLogo.svg" alt="Devreg Logo" className="h-8" />
        </div>
        <hr className="w-full border-t border-gray-200" />
        <div className="flex flex-col items-center mt-8 gap-2 justify-center max-md:px-4">
          <FaCircleCheck className="text-devRegGreen w-12 h-12 mb-4" />
          <div className="text-lg font-bold text-devRegDarkBlue text-center">
            Your password has been updated!
          </div>
        </div>
        <div className="mt-4 text-sm leading-5 px-6 text-gray-400 text-center">
          For security purposes we’ve sent an email to your account confirming
          this change.
        </div>
        <div
          className="cursor-pointer text-center justify-center items-center self-center px-9 py-3 mt-8 w-full text-base font-normal text-white whitespace-nowrap bg-blue-900 rounded-3xl max-w-[371px] max-md:px-5"
          onClick={redirectToLogin}
        >
          Login
        </div>
      </div>
    </div>
  );
}

export default PassUpdateSuccess;
